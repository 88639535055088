import LinkIcon from '@components/deprecatedTookit/icons/fa/light/LinkIcon';
import { DeliveryPreference } from '@client/_blessed/store/entities/departments/model';
import MatchRowItem from './components/MatchRowItem';
import { BundleGeneratorMatchInfo } from '../store';

import './style.css';

interface MatchRowProps {
  primary: BundleGeneratorMatchInfo;
  match: BundleGeneratorMatchInfo;
  onMergeClick: (mergeInfo: { primary: BundleGeneratorMatchInfo; match: BundleGeneratorMatchInfo }) => Promise<void>;
  onMergeAndPostClick: (mergeInfo: {
    primary: BundleGeneratorMatchInfo;
    match: BundleGeneratorMatchInfo;
  }) => Promise<void>;
  isMergingBundles: boolean;
}

function MatchRow({ primary, match, onMergeClick, onMergeAndPostClick, isMergingBundles }: MatchRowProps) {
  const baseClassName = 'MatchRow';

  const primaryConsigneeDepartment = primary.stops.find((stop) => stop.type === 'consignee')?.department;
  let primaryConsigneeDeliveryPreference: DeliveryPreference<any> | null = null;
  if (primaryConsigneeDepartment) {
    primaryConsigneeDeliveryPreference =
      primary.importExport === 'import'
        ? primaryConsigneeDepartment.importDeliveryPreference || null
        : primaryConsigneeDepartment.exportDeliveryPreference || null;
  }

  const matchConsigneeDepartment = match.stops.find((stop) => stop.type === 'consignee')?.department;
  let matchConsigneeDeliveryPreference: DeliveryPreference<any> | null = null;
  if (matchConsigneeDepartment) {
    matchConsigneeDeliveryPreference =
      match.importExport === 'import'
        ? matchConsigneeDepartment.importDeliveryPreference || null
        : matchConsigneeDepartment.exportDeliveryPreference || null;
  }

  const canMergeAndPost =
    primaryConsigneeDeliveryPreference?.openTwentyFourHours && matchConsigneeDeliveryPreference?.openTwentyFourHours;
  let mergeAndPostButtonClassName = `${baseClassName}__action-button`;
  if (!canMergeAndPost) {
    mergeAndPostButtonClassName = `${baseClassName}__action-button--disabled ${mergeAndPostButtonClassName}`;
  }

  return (
    <div className={baseClassName}>
      <div className={`${baseClassName}__action-container`}>
        <div
          onClick={() => onMergeClick({ primary, match })}
          className={`${
            isMergingBundles ? `${baseClassName}__action-button--loading` : ''
          }${baseClassName}__action-button`}
        >
          Merge
        </div>
        <div
          className={`${
            isMergingBundles ? `${baseClassName}__action-button--loading` : ''
          }${mergeAndPostButtonClassName}`}
          onClick={() => {
            if (canMergeAndPost) {
              onMergeAndPostClick({ primary, match });
            }
          }}
        >
          Merge & Post
        </div>
        <div
          className={`${baseClassName}__action-button--loading ${baseClassName}__action-button--reject ${baseClassName}__action-button`}
        >
          Reject
        </div>
      </div>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
        <MatchRowItem info={match} />
        <LinkIcon className={`${baseClassName}__link-icon`} />
        <MatchRowItem info={primary} />
      </div>
    </div>
  );
}

export default MatchRow;
