import { selectDepartmentUuidMap } from '@client/_blessed/store/entities';
import Stop from '@client/_blessed/store/entities/stops/model';
import DispatchPageOrderLegStop from '@client/pages/DispatchPage/components/DispatchPageOrderLegStop';
import { useSelector } from 'react-redux';

import './style.css';

interface MatchRowStopProps {
  stop: Stop;
  stopIndex: number;
  isFirstOfLeg: boolean;
  isLastOfLeg: boolean;
  status: string | null;
}

function MatchRowStop({ stop, stopIndex, isFirstOfLeg, isLastOfLeg, status }: MatchRowStopProps) {
  const departments = useSelector(selectDepartmentUuidMap);

  return (
    <div className="MatchRowStop">
      <DispatchPageOrderLegStop
        key={stop.uuid}
        stop={stop}
        departmentsById={departments}
        containerNumber=""
        disableAdd
        hideGateTicketModal
        outgateIngateEditable={false}
        locked
        progressNotifications={null}
        attachmentsById={{}}
        bundle={{}}
        status={status}
        isFirstStop={isFirstOfLeg}
        isFirstOfLeg={isFirstOfLeg}
        isLastStop={isLastOfLeg}
        isLastOfLeg={isLastOfLeg}
        orderId=""
        orderImportExport=""
        onDelete={() => {}}
        onFieldChange={() => {}}
        onOrderUpdated={() => {}}
        legId=""
        position={stopIndex}
        onExecuteAction={() => {}}
        toggleSyncDialog={() => {}}
        showSync={false}
        firstSyncEnabled={false}
        lastSyncEnabled={false}
        nextStop={null}
        isSystemGeneratedAppointment={false}
        onAddToEmailBatch={() => {}}
        nextLeg={null}
        prevLeg={null}
        leg={{}}
        fromBundleSidebar
        bundleLegRelation={{} as any}
      />
    </div>
  );
}

export default MatchRowStop;
