import { useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import LoadingSpinner from '@client/components/LoadingSpinner/component';
import { selectDepartmentsLoading } from '@client/_blessed/store/entities';
import { mergeBundles, setBundleSync, syncBundleDepartments } from '@client/state/resources/bundle/actions';
import { logAnalyticsClick, logAnalyticsPageView } from '@client/utils/analytics';
import moment from 'moment-timezone';
import { updateStopInstants } from '@client/state/resources/stop-instants/actions';
import { updateLegs } from '@client/state/resources/legs/actions';
import { openTab } from '@client/utils/url';
import {
  BundleGeneratorMatchInfo,
  fetchBundleGeneratorScheme,
  selectBundleGeneratorLoading,
  selectBundleGeneratorMatchInfo,
  selectBundleGeneratorMatchLegNumbers,
  selectBundleGeneratorMatches
} from './store';
import { fetchLegStopActions, selectLegStopActionsLoading } from '../../../Dispatch/store';
import MatchRow from './MatchRow/component';

import './style.css';

const PAGE_TITLE = 'Dual Trans Bundle Generator';

interface BundleGeneratorProps {
  legNumber?: number;
}

const mergePrimaryAndMatchBundles = async ({
  primary,
  match
}: {
  primary: BundleGeneratorMatchInfo;
  match: BundleGeneratorMatchInfo;
}) => {
  const { bundle: newBundle } = await mergeBundles({
    data: {
      primaryBundleUuid: primary.bundleUuid,
      secondaryBundleUuid: match.bundleUuid,
      bundleType: 'dualTrans',
      prepend: true
    }
  });

  if (newBundle) {
    await setBundleSync(
      {
        bundleUuid: newBundle.uuid,
        primaryStopInstant: primary.stops.find((stop) => stop.type === 'terminal')!.departureStopInstant.uuid,
        sync: true
      },
      undefined,
      true
    );

    await syncBundleDepartments({
      bundleUuid: newBundle.uuid,
      positionToSyncFrom: 1,
      positionToSyncTo: 0
    });
  }
};

function BundleGenerator(props: BundleGeneratorProps) {
  const baseClassName = 'BundleGenerator';

  const appDispatch = useAppDispatch();

  const isDepartmentsLoading = useSelector(selectDepartmentsLoading);
  const isLegStopActionsLoading = useSelector(selectLegStopActionsLoading);
  const isLoading = useSelector(selectBundleGeneratorLoading);

  const bundleGeneratorMatches = useSelector(selectBundleGeneratorMatches);
  const bundleGeneratorMatchInfo = useSelector(selectBundleGeneratorMatchInfo);
  const bundleGeneratorMatchLegNumbers = useSelector(selectBundleGeneratorMatchLegNumbers);

  const [containerFilter, setContainerFilter] = useState('');

  const [isMergingBundles, setIsMergingBundles] = useState(false);
  const [mergingMessage, setMergingMessage] = useState<string | null>(null);
  const [mergedMessage, setMergedMessage] = useState<string | null>(null);
  const [mergedLegNumbers, setMergedLegNumbers] = useState<string[]>([]);

  const sortedBundleGeneratorMatches = useMemo(() => {
    const sortedMatches: Record<string, { primary: number; matchCandidates: number[] }> = {};

    Object.keys(bundleGeneratorMatches).forEach((legNumber) => {
      const { primary, matchCandidates } = bundleGeneratorMatches[legNumber];

      const sortedMatchCandidates = [...matchCandidates].filter((match) => {
        if (containerFilter) {
          return bundleGeneratorMatchInfo[match].containerNumber.toLowerCase().includes(containerFilter.toLowerCase());
        }

        return true;
      });

      sortedMatches[legNumber] = {
        primary,
        matchCandidates: sortedMatchCandidates
      };
    });

    return sortedMatches;
  }, [containerFilter, bundleGeneratorMatches, bundleGeneratorMatchInfo]);

  const handleBundleJoinClick = async ({
    primary,
    match
  }: {
    primary: BundleGeneratorMatchInfo;
    match: BundleGeneratorMatchInfo;
  }) => {
    try {
      logAnalyticsClick('merge', 'bundle-generator', {
        primarylegNumber: primary.legNumber,
        matchLegNumber: match.legNumber
      });
      setIsMergingBundles(true);

      setMergingMessage('Merging Bundles...');
      mergePrimaryAndMatchBundles({ primary, match });

      setMergedMessage('Bundles Merged Successfully!');
      setMergedLegNumbers([`${primary.legNumber}`, `${match.legNumber}`]);

      setIsMergingBundles(false);
      setMergingMessage(null);
    } catch (err) {
      setIsMergingBundles(false);
    }
  };

  const handleBundleJoinAndPostClick = async ({
    primary,
    match
  }: {
    primary: BundleGeneratorMatchInfo;
    match: BundleGeneratorMatchInfo;
  }) => {
    try {
      logAnalyticsClick('merge & post', 'bundle-generator', {
        primarylegNumber: primary.legNumber,
        matchLegNumber: match.legNumber
      });

      setIsMergingBundles(true);

      // Merge the bundles
      setMergingMessage('Merging Bundles...');
      await mergePrimaryAndMatchBundles({ primary, match });

      // Update the match consignee appointment to be 3 hours before the terminal appointment
      const primaryTerminalStopIndex = primary.stops.findIndex((stop) => stop.type === 'terminal');
      const primaryTerminalStop = primary.stops[primaryTerminalStopIndex];
      const primaryTerminalStopInstant =
        primaryTerminalStopIndex === 0
          ? primaryTerminalStop.departureStopInstant
          : primaryTerminalStop.arrivalStopInstant;

      const primaryConsigneeStopIndex = primary.stops.findIndex((stop) => stop.type === 'consignee');
      const primaryConsigneeStop = primary.stops[primaryConsigneeStopIndex];
      const primaryConsigneeStopInstant =
        primaryConsigneeStopIndex === 0
          ? primaryConsigneeStop.departureStopInstant
          : primaryConsigneeStop.arrivalStopInstant;

      const matchConsigneeStopIndex = match.stops.findIndex((stop) => stop.type === 'consignee');
      const matchConsigneeStop = match.stops[matchConsigneeStopIndex];
      const matchConsigneeStopInstant =
        matchConsigneeStopIndex === 0 ? matchConsigneeStop.departureStopInstant : matchConsigneeStop.arrivalStopInstant;

      setMergingMessage('Updating return appointment times...');
      await updateStopInstants([
        {
          id: matchConsigneeStopInstant.uuid,
          type: 'stopInstant',
          attributes: {
            appointmentStart: moment(primaryTerminalStopInstant.appointmentStart!)
              .tz(primaryTerminalStop.department!.timezone)
              .subtract(3, 'hours')
              .format(),
            appointmentEnd: moment(primaryTerminalStopInstant.appointmentEnd!)
              .tz(primaryTerminalStop.department!.timezone)
              .subtract(3, 'hours')
              .format()
          }
        }
      ])();

      setMergingMessage('Updating pickup leg consignee appointment...');
      await updateStopInstants([
        {
          id: primaryConsigneeStopInstant.uuid,
          type: 'stopInstant',
          attributes: {
            appointmentStart: moment(primaryTerminalStopInstant.appointmentStart!)
              .tz(primaryTerminalStop.department!.timezone)
              .add(3, 'hours')
              .format(),
            appointmentEnd: moment(primaryTerminalStopInstant.appointmentEnd!)
              .tz(primaryTerminalStop.department!.timezone)
              .add(3, 'hours')
              .format()
          }
        }
      ])();

      setMergingMessage('Updating pickup leg to drop w/o backhaul...');
      await updateLegs([
        {
          type: 'leg',
          id: primary.legUuid,
          attributes: {
            type: 'drop_only'
          }
        }
      ])();

      // Post the bundle to the job board (aka. ready for pickup)
      setMergingMessage('Posting bundle to job board...');
      await updateLegs([
        {
          type: 'leg',
          id: primary.legUuid,
          attributes: {
            status: 'ready_for_pickup'
          }
        }
      ])();

      setMergedMessage('Bundles Merged and Posted Successfully!');
      setMergedLegNumbers([`${primary.legNumber}`, `${match.legNumber}`]);

      setIsMergingBundles(false);
      setMergingMessage(null);
    } catch (err) {
      setIsMergingBundles(false);
    }
  };

  useEffect(() => {
    logAnalyticsPageView('dispatch/bundle-generator');
  }, []);

  useEffect(() => {
    const bundleGenPromise = appDispatch(
      fetchBundleGeneratorScheme({ scheme: 'reverse-duals', legNumber: props.legNumber })
    );
    const legStopActionsPromise = appDispatch(fetchLegStopActions());

    return () => {
      bundleGenPromise.abort();
      legStopActionsPromise.abort();

      if (mergedMessage) {
        openTab({
          filters: btoa(JSON.stringify({ legNumber: mergedLegNumbers }))
        });
      }
    };
  }, [props.legNumber, mergedMessage, mergedLegNumbers, openTab]);

  if (isMergingBundles || isLoading || isDepartmentsLoading || isLegStopActionsLoading || !bundleGeneratorMatches) {
    return (
      <div className={baseClassName}>
        <h1>{PAGE_TITLE}</h1>
        <LoadingSpinner className="BundleGenerator--loading" />
        {mergingMessage && <div className="BundleGenerator--message">{mergingMessage}</div>}
      </div>
    );
  }

  return (
    <div className={baseClassName}>
      <h1>{PAGE_TITLE}</h1>
      {!mergedMessage && (
        <input
          type="text"
          className="BundleGenerator--container-number-filter"
          aria-label="Filter by container number"
          placeholder="Filter by container number"
          onChange={(e) => setContainerFilter(e.target.value)}
        />
      )}

      {mergedMessage && <div>{mergedMessage}</div>}

      {!mergedMessage && bundleGeneratorMatchLegNumbers.length === 0 && (
        <div>No dual trans bundle candidates found</div>
      )}

      {!mergedMessage &&
        bundleGeneratorMatchLegNumbers.map((legNumber) => {
          const { primary, matchCandidates } = sortedBundleGeneratorMatches[legNumber];

          return (
            <div key={legNumber}>
              {!props.legNumber && <h3>Matches For: {legNumber}</h3>}

              {matchCandidates.length === 0 && <div>No dual trans bundle candidates found for this leg</div>}

              {matchCandidates.map((match) => (
                <MatchRow
                  key={`${legNumber}-${bundleGeneratorMatchInfo[match].legNumber}}`}
                  primary={bundleGeneratorMatchInfo[primary]}
                  match={bundleGeneratorMatchInfo[match]}
                  onMergeClick={handleBundleJoinClick}
                  onMergeAndPostClick={handleBundleJoinAndPostClick}
                  isMergingBundles={isMergingBundles}
                />
              ))}
            </div>
          );
        })}
    </div>
  );
}

export default BundleGenerator;
