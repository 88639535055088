// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MatchRowItem {
  display: flex;
  flex-direction: column;
  width: 600px;
  border: 1px solid gray;
  border-radius: 5px;
}

  .MatchRowItem__bottom-section-container {
      display: flex;
      justify-content: center;
    }

  .MatchRowItem__stops-container {
    display: flex;
    padding: 5px;
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/BundleGenerator/MatchRow/components/MatchRowItem/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;AAapB;;EAVI;MACE,aAAa;MACb,uBAAuB;IACzB;;EAGF;IACE,aAAa;IACb,YAAY;EACd","sourcesContent":[".MatchRowItem {\n  display: flex;\n  flex-direction: column;\n  width: 600px;\n  border: 1px solid gray;\n  border-radius: 5px;\n\n  &__bottom-section {\n    &-container {\n      display: flex;\n      justify-content: center;\n    }\n  }\n\n  &__stops-container {\n    display: flex;\n    padding: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
