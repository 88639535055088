// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MatchRow {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid lightgray;
  margin-bottom: 5px;
}

  .MatchRow:last-child {
    margin-bottom: 0;
  }

  .MatchRow__action-container {
    display: flex;
    width: 100%;
    justify-content: end;
    margin-bottom: 10px;
  }

  .MatchRow__action-button {
    background-color: #41A741;
    color: #fff;
    margin-right: 15px;
    padding: 8px 10px;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
  }

  .MatchRow__action-button:last-child {
      margin-right: 0;
    }

  .MatchRow__action-button--reject {
      background-color: #000000;
    }

  .MatchRow__action-button--loading {
      background-color: #808080;
    }

  .MatchRow__action-button--disabled {
      /* display: none; */
      background-color: #808080;
    }

  .MatchRow__link-icon {
    background-color: #808080;
    color: #fff;
    height: 24px;
    width: 24px;
    margin: 5px;
    padding: 5px;
    border-radius: 2px;
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/BundleGenerator/MatchRow/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,2BAA2B;EAC3B,kBAAkB;AAiDpB;;EA/CE;IACE,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,WAAW;IACX,oBAAoB;IACpB,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB,WAAW;IACX,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;EAkBjB;;EAhBE;MACE,eAAe;IACjB;;EAEA;MACE,yBAAyB;IAC3B;;EAEA;MACE,yBAAyB;IAC3B;;EAEA;MACE,mBAAmB;MACnB,yBAAyB;IAC3B;;EAGF;IACE,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,WAAW;IACX,WAAW;IACX,YAAY;IACZ,kBAAkB;EACpB","sourcesContent":[".MatchRow {\n  display: flex;\n  flex-direction: column;\n  padding: 10px;\n  border: 1px solid lightgray;\n  margin-bottom: 5px;\n\n  &:last-child {\n    margin-bottom: 0;\n  }\n\n  &__action-container {\n    display: flex;\n    width: 100%;\n    justify-content: end;\n    margin-bottom: 10px;\n  }\n\n  &__action-button {\n    background-color: #41A741;\n    color: #fff;\n    margin-right: 15px;\n    padding: 8px 10px;\n    border-radius: 5px;\n    font-weight: 500;\n    cursor: pointer;\n\n    &:last-child {\n      margin-right: 0;\n    }\n\n    &--reject {\n      background-color: #000000;\n    }\n\n    &--loading {\n      background-color: #808080;\n    }\n\n    &--disabled {\n      /* display: none; */\n      background-color: #808080;\n    }\n  }\n\n  &__link-icon {\n    background-color: #808080;\n    color: #fff;\n    height: 24px;\n    width: 24px;\n    margin: 5px;\n    padding: 5px;\n    border-radius: 2px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
