import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import request from '@client/utils/request';
import { api } from '@client/utils/url';
import Stop from '@client/_blessed/store/entities/stops/model';
import { selectAdminSlice } from '../../../store/selectors';

const TBD_DEPARTMENT_UUID = '7b1d3e6d-37b9-ce73-2245-5994a51cabf5';

export interface BundleGeneratorMatchInfo {
  legNumber: number;
  legUuid: string;
  steamShippingLineAccountUuid: string;
  status: string;
  orderNumber: string;
  containerSize: string;
  containerNumber: string;
  importExport: 'import' | 'export';
  deliveryType: string;
  carrierUuid: string;
  driverUuid: string;
  truckUuid: string;
  visibility: string;
  emptyReadyAt: string;
  lastFreeDay: string;
  perDiemDueDate: string;
  chassisNumber: string;
  chassisPool?: {
    abbreviation: string;
    badgeColor: string;
  };
  bookingBL: string;
  bundleUuid: string;

  stops: Stop[];
}

export type BundleGeneratorMatch = [BundleGeneratorMatchInfo, BundleGeneratorMatchInfo];

interface FetchBundleGeneatorSchemeResponse {
  matches: Record<string, { primary: number; matchCandidates: number[] }>;
  matchInfo: Record<number, BundleGeneratorMatchInfo>;
}

export const fetchBundleGeneratorScheme = createAsyncThunk(
  'fetchBundleGeneratorScheme',
  async (params: { scheme: string; legNumber?: number }, thunkAPI) => {
    try {
      let url = api(`/bundle/generator/scheme/${params.scheme}`);

      if (params.legNumber) {
        url += `?legNumber=${params.legNumber}`;
      }

      const response = await request<FetchBundleGeneatorSchemeResponse>(url);

      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

interface BundleGeneratorState {
  loading: boolean;
  matches: Record<string, { primary: number; matchCandidates: number[] }>;
  matchInfo: Record<number, BundleGeneratorMatchInfo>;
}

const getInitialState = (state: any = {}): BundleGeneratorState => {
  state.loading = false;
  state.matches = {};

  return state;
};

const sortByLastFreeDay = (a: BundleGeneratorMatchInfo, b: BundleGeneratorMatchInfo) => {
  if (a.lastFreeDay && b.lastFreeDay) {
    return moment(a.lastFreeDay).diff(moment(b.lastFreeDay));
  }

  // If one of the LFDs is missing, sort it to the end
  if (!a.lastFreeDay) {
    return 1;
  }

  if (!b.lastFreeDay) {
    return -1;
  }

  return 0;
};

const bundleGeneratorSlice = createSlice({
  name: 'bundleGenerator',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBundleGeneratorScheme.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchBundleGeneratorScheme.fulfilled, (state, action) => {
      state.loading = false;

      const bundleMatches = { ...action.payload?.matches };

      // eslint-disable-next-line no-restricted-syntax
      for (const legNumber of Object.keys(bundleMatches)) {
        bundleMatches[legNumber].matchCandidates = bundleMatches[legNumber].matchCandidates
          .sort((legA, legB) => {
            const a = action.payload?.matchInfo[legA];
            const b = action.payload?.matchInfo[legB];

            if (!a || !b) {
              return 0;
            }

            // TBD department should always be at the end
            if (a.stops[a.stops.length - 1].departmentUuid === TBD_DEPARTMENT_UUID) {
              return 1;
            }

            if (b.stops[b.stops.length - 1].departmentUuid === TBD_DEPARTMENT_UUID) {
              return -1;
            }

            return 0;
          })
          .sort((legA, legB) => {
            const a = action.payload?.matchInfo[legA];
            const b = action.payload?.matchInfo[legB];

            if (!a || !b) {
              return 0;
            }

            return sortByLastFreeDay(a, b);
          });
      }

      state.matches = bundleMatches;
      state.matchInfo = action.payload?.matchInfo || {};
    });
    builder.addCase(fetchBundleGeneratorScheme.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const selectBundleGeneratorSlice = createSelector(
  selectAdminSlice,
  (state) => state.bundleGenerator as BundleGeneratorState
);

export const selectBundleGeneratorLoading = createSelector(selectBundleGeneratorSlice, (state) => state.loading);

export const selectBundleGeneratorMatches = createSelector(selectBundleGeneratorSlice, (state) => state.matches);

export const selectBundleGeneratorMatchInfo = createSelector(selectBundleGeneratorSlice, (state) => state.matchInfo);

export const selectBundleGeneratorMatchLegNumbers = createSelector(selectBundleGeneratorMatches, (matches) =>
  Object.keys(matches)
);

export default bundleGeneratorSlice;
